import Analyzer from 'components/mlbAnalyzer/Analyzer';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Page404 from 'components/Page404';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initializeTeamData } from 'reducers/mlbDataReducer';
import { initializeCompetitionsData } from 'reducers/competitionsReducer';
import Navigation from 'components/navigation/Navigation';
import moment, { Moment } from 'moment-timezone';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { Login } from 'components/login';
import { ModalContextProvider } from 'context/ModalContext';
import Competitions from 'components/competitions/Competitions'



const App = () => {
  const dispatch = useDispatch<any>();

  let userTimezone = moment.tz.guess();
  // let userTimeDiffToUTC = new Date().getTimezoneOffset();
  let currentUserDate = moment.tz(new Date(), userTimezone);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(currentUserDate)

  let [selectedLeagueCode, setSelectedLeagueCode] = useState<LeagueCode>(LeagueCode.NCAAF);

  useEffect(() => {
    dispatch(initializeTeamData());
    dispatch(initializeCompetitionsData());
  }, [dispatch]);
  
  return (
    
      <div className="App">
        <ModalContextProvider>
          <Navigation
            selectedLeagueCode={selectedLeagueCode}
            setSelectedLeagueCode={setSelectedLeagueCode}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          
          <Routes>
            <Route
              path="/"
              element={<Login />}
            />
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/competitions"
              element={
                <Competitions
                  selectedLeagueCode={selectedLeagueCode}
                  selectedDate={selectedDate}
                />
              }
            />

            <Route path="/analyzer" element={<Analyzer selectedLeagueCode={selectedLeagueCode} />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ModalContextProvider>
        
      </div>
  );
}

export default App;
