export enum NHL_TEAM_CODE {
  ANA = 'ANA',
  ARI = 'ARI', /* this is going to change to UTA soon */
  BOS = 'BOS',
  BUF = 'BUF',
  CGY = 'CGY',
  CAR = 'CAR',
  CHI = 'CHI',
  COL = 'COL',
  CLB = 'CLB',
  DAL = 'DAL',
  DET = 'DET',
  EDM = 'EDM',
  FLA = 'FLA',
  LA = 'LA',
  LV = 'LV',
  MIN = 'MIN',
  MTL = 'MTL',
  NSH = 'NSH',
  NJ = 'NJ',
  NYI = 'NYI',
  NYR = 'NYR',
  OTT = 'OTT',
  PHI = 'PHI',
  PIT = 'PIT',
  SJ = 'SJ',
  SEA = 'SEA',
  STL = 'STL',
  TB = 'TB',
  TOR = 'TOR',
  UTA = 'UTA',
  VAN = 'VAN',
  WAS = 'WAS',
  WPG = 'WPG',
};

export enum NHL_TEAM_NAME_LONG {
  ANA = 'Anaheim Ducks',
  ARI = 'Arizona Coyotes', /* this is going to change to UTA soon */
  BOS = 'Boston Bruins',
  BUF = 'Buffalo Sabres',
  CGY = 'Calgary Flames',
  CAR = 'Carolina Hurricanes',
  CHI = 'Chicago Blackhawks',
  COL = 'Colorado Avalanche',
  CLB = 'Columbus Blue Jackets',
  DAL = 'Dallas Stars',
  DET = 'Detroit Red Wings',
  EDM = 'Edmonton Oilers',
  FLA = 'Florida Panthers',
  LA = 'Los Angeles Kings',
  LV = 'Vegas Golden Knights',
  MIN = 'Minnesota Wild',
  MTL = 'Montreal Canadiens',
  NSH = 'Nashville Predators',
  NJ = 'New Jersey Devils',
  NYI = 'New York Islanders',
  NYR = 'New York Rangers',
  OTT = 'Ottawa Senators',
  PHI = 'Philadelphia Flyers',
  PIT = 'Pittsburgh Penguins',
  SJ = 'San Jose Sharks',
  SEA = 'Seattle Kraken',
  STL = 'St Louis Blues',
  TB = 'Tampa Bay Lightning',
  TOR = 'Toronto Maple Leafs',
  UTA = 'Utah Hockey Club',
  VAN = 'Vancouver Canucks',
  WAS = 'Washington Capitals',
  WPG = 'Winnipeg Jets',
};

export const NHL_COLOR_CODES: {  [key in NHL_TEAM_CODE]: { primary: string, secondary: string } } = {
  ANA: { primary: 'rgb(252, 76, 2, 0.75)', secondary: 'rgb(185, 151, 91, 0.75)' },
  ARI: { primary: 'rgb(140, 38, 51, 0.75)', secondary: 'rgb(226, 214, 181, 0.75)' },
  BOS: { primary: 'rgb(252, 181, 20, 0.75)', secondary: 'rgb(17, 17, 17, 0.75)' },
  BUF: { primary: 'rgb(0, 48, 135, 0.75)', secondary: 'rgb(255, 184, 28, 0.75)' },
  CGY: { primary: 'rgb(210, 0, 28, 0.75)', secondary: 'rgb(250, 175, 25, 0.75)' },
  CAR: { primary: 'rgb(206, 17, 38, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  CHI: { primary: 'rgb(207, 10, 44, 0.75)', secondary: 'rgb(255, 103, 27, 0.75)' },
  COL: { primary: 'rgb(11, 38, 61, 0.75)', secondary: 'rgb(35, 97, 146, 0.75)' },
  CLB: { primary: 'rgb(0, 38, 34, 0.75)', secondary: 'rgb(206, 17, 38, 0.75)' },
  DAL: { primary: 'rgb(0, 104, 71, 0.75)', secondary: 'rgb(143, 143, 140, 0.75)' },
  DET: { primary: 'rgb(206, 17, 38 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  EDM: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(252, 76, 0, 0.75)' },
  FLA: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(200, 16, 46, 0.75)' },
  LA: { primary: 'rgb(17, 17, 17, 0.75)', secondary: 'rgb(162, 170, 173, 0.75)' },
  LV: { primary: 'rgb(185, 151, 91, 0.75)', secondary: 'rgb(51, 63, 72, 0.75)' },
  MIN: { primary: 'rgb(175, 35, 36, 0.75)', secondary: 'rgb(2, 73, 48, 0.75)' },
  MTL: { primary: 'rgb(175, 30, 45, 0.75)', secondary: 'rgb(25, 33, 104, 0.75)' },
  NSH: { primary: 'rgb(255, 184, 28, 0.75)', secondary: 'rgb(4, 30, 66, 0.75)' },
  NJ: { primary: 'rgb(206, 17, 38, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  NYI: { primary: 'rgb(0, 83, 155, 0.75)', secondary: 'rgb(244, 125, 48, 0.75)' },
  NYR: { primary: 'rgb(0, 56, 168, 0.75)', secondary: 'rgb(206, 17, 38, 0.75)' },
  OTT: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(218, 26, 50, 0.75)' },
  PHI: { primary: 'rgb(247, 73, 2, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  PIT: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(207, 196, 147, 0.75)' },
  SJ: { primary: 'rgb(0, 109, 117, 0.75)', secondary: 'rgb(234, 114, 0, 0.75)' },
  SEA: { primary: 'rgb(0, 22, 40, 0.75)', secondary: 'rgb(153, 217, 217, 0.75)' },
  STL: { primary: 'rgb(0, 47, 135, 0.75)', secondary: 'rgb(252, 181, 20, 0.75)' },
  TB: { primary: 'rgb(0, 40, 104, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  TOR: { primary: 'rgb(3, 32, 91, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UTA: { primary: 'rgb(113, 175, 229, 0.75)', secondary: 'rgb(9, 9, 9, 0.75)' },
  VAN: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(10, 134, 61, 0.75)' },
  WAS: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(200, 16, 46, 0.75)' },
  WPG: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(0, 76, 151, 0.75)' },
};