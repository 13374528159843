import { OverOrUnder, PickType } from 'config/competitions';
import { HomeOrAway } from 'reducers/types/competitionTypes';
import { MLBPlayers } from 'reducers/types/mlbTypes';

export const truncateMetric = (metric: number, truncationLength = 3) => {
  return metric.toFixed(truncationLength)
};

export const getCorrelationColor = (correlationValue: number) => {
  let darkRed = '#b40625';
  let midRed = '#d14c42';
  let lightRed = '#f19678';
  let nearNeutralRed = '#f0cdbd';
  let nearNeutralBlue = '#cfd8e5';
  let lightBlue = '#b1b2fe';
  let midBlue = '#6281e8';
  let darkBlue = '#465fd1';

  let borderColor = '#FFF';
  if (
    (correlationValue < 1 && correlationValue > 0.9) ||
    (correlationValue > -1 && correlationValue < -0.9)
  ) {
    borderColor = darkRed;
  } else if (
    (correlationValue <0.9 && correlationValue > 0.8) ||
    (correlationValue > -0.9 && correlationValue < -0.8)
  ) {
    borderColor = midRed;
  } else if (
    (correlationValue < 0.8 && correlationValue > 0.7) ||
    (correlationValue > -0.8 && correlationValue < -0.7)
  ) {
    borderColor = lightRed;
  } else if (
    (correlationValue < 0.7 && correlationValue > 0.6) ||
    (correlationValue > -0.7 && correlationValue < -0.6)
  ) {
    borderColor = nearNeutralRed;
  } else if (
    (correlationValue < 0.6 && correlationValue > 0.5) ||
    (correlationValue > -0.6 && correlationValue < -0.5)
  ) {
    borderColor = nearNeutralBlue;
  } else if (
    (correlationValue < 0.5 && correlationValue > 0.4) ||
    (correlationValue > -0.5 && correlationValue < -0.4)
  ) {
    borderColor = lightBlue;
  } else if (
    (correlationValue < 0.4 && correlationValue > 0.3) ||
    (correlationValue > -0.4 && correlationValue < -0.3)
  ) {
    borderColor = midBlue;
  } else if (
    (correlationValue > 0.3 && correlationValue < -0.3)
  ) {
    borderColor = darkBlue;
  };;


  return borderColor
};

export const getZScoreWinnerForStat = (correlationValue: number, awayZScore: number, homeZScore: number) => {
  let winner: HomeOrAway;

  const away: HomeOrAway = 'away';
  const home: HomeOrAway = 'home';

  if (correlationValue > 0) {
    winner = awayZScore > homeZScore ? away : home;
  } else {
    winner = awayZScore < homeZScore ? away : home;
  }
  return winner;
};

export const getNumberWithOrdinal = (n:number) => {
  var s = ["th", "st", "nd", "rd"],
      v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const getPitcherNames = (pitchers: MLBPlayers) => {
  let pitcherNames: string[] = [''];
  for (const pitcher in pitchers) {
    pitcherNames.push(pitchers[pitcher].name) 
  };
  return pitcherNames.sort();
}
export const formatNumberForDisplay = (spread: number) => {
  return spread > 0 ? `+${spread}` : spread.toString();
};

/**
 * 
 * @param decimalPercentage must be in decimal format: 0.07 => 7%
 * @returns 
 */
export const formatPercentage = (decimalPercentage: number) => {
  return (decimalPercentage * 100).toFixed(1) + '%';
};

export const formatOverUnderForDisplay = (overOrUnder: OverOrUnder, totalPoints: number) => {
  return overOrUnder === 'over' ? `O ${totalPoints}` : `U ${totalPoints}`;
};

// location.pathname returns a prepending "/" and since that
// is the primary thing using this helper, just strip it off
export const formatPageTitle = (title: string) => {
  return title.slice(1).replace('-', ' ').toUpperCase();
};

export const getFirstName = (teamNameLong: string) => {
  switch(teamNameLong.toLowerCase()) {
    case 'columbus blue jackets':
      return 'Columbus';
    case 'detroit red wings':
      return 'Detroit';
    case 'utah hockey club':
      return 'Utah';
    case 'toronto maple leafs':
      return 'Toronto';
    case 'vegas golden knights':
      return 'Vegas';

    case 'portland trail blazers':
      return 'Portland';

    case 'boston red sox':
      return 'Boston';
    case 'chicago white sox':
      return 'Chicago';
    case 'toronto blue jays':
      return 'Toronto';

    case 'georgia tech yellow jackets':
      return 'Georgia Tech';
    case 'nevada wolf pack':
      return 'Nevada';

    default:
      return teamNameLong.split(" ").slice(0, -1).join(" ");
  }
};

// may need to log special use cases in here or find a more determinate
// way of getting this as .split(' ').pop() only work when the team "mascot"
// is one word. "White sox", 'blue jackets" would not work
export const getLastName = (teamNameLong: string) => {

  switch(teamNameLong.toLowerCase()) {
    case 'detroit red wings':
      return 'Red Wings';
    case 'columbus blue jackets':
      return 'Blue Jackets';
    case 'utah hockey club':
      return 'Hockey Club';
    case 'toronto maple leafs':
      return 'Maple Leafs';
    case 'vegas golden knights':
      return 'Golden Knights';

    case 'portland trail blazers':
      return 'Trail Blazers';

    case 'boston red sox':
      return 'Red Sox';
    case 'chicago white sox':
      return 'White Sox';
    case 'toronto blue jays':
      return 'Blue Jays';

    case 'georgia tech yellow jackets':
      return 'Yellow Jackets';
    case 'nevada wolf pack':
      return 'Wolf Pack';
    default:
      return teamNameLong.split(' ').pop() || '';
  }
};

// color should be in RGB mode with opacity: 'rgb(0, 163, 224, 0.75)'
export const getBoldTeamColor = (teamColor: string) => {
  return teamColor.split(',').slice(0,3)?.concat('1)').join()
};

export const formattOddsOrPickType = (prefixText: string, oddOrPickType: PickType) => {
  let formattedString = prefixText;

  if (oddOrPickType === PickType.SPREAD) return `${prefixText} Spread`;
  if (oddOrPickType === PickType.MONEYLINE) return `${prefixText} ML`;
  if (oddOrPickType === PickType.OVERUNDER) return `${prefixText} O/U`;

  return formattedString;
}