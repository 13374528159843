export enum NBA_TEAM_CODE {
  ATL = 'ATL',
  BOS = 'BOS',
  BKN = 'BKN',
  CHA = 'CHA',
  CHI = 'CHI',
  CLE = 'CLE',
  DAL = 'DAL',
  DEN = 'DEN',
  DET = 'DET',
  GS = 'GS',
  HOU = 'HOU',
  IND = 'IND',
  LAC = 'LAC',
  LAL = 'LAL',
  MEM = 'MEM',
  MIA = 'MIA',
  MIL = 'MIL',
  MIN = 'MIN',
  NO = 'NO',
  NY = 'NY',
  OKC = 'OKC',
  ORL = 'ORL',
  PHI = 'PHI',
  PHO = 'PHO',
  POR = 'POR',
  SAC = 'SAC',
  SA = 'SA',
  TOR = 'TOR',
  UTA = 'UTA',
  WAS = 'WAS',
};

export enum NBA_TEAM_NAME_LONG {
  ATL = 'Atlanta Hawks',
  BOS = 'Boston Celtics',
  BKN = 'Brooklyn Nets',
  CHA = 'Charlotte Hornets',
  CHI = 'Chicago Bulls',
  CLE = 'Cleveland Cavaliers',
  DAL = 'Dallas Mavericks',
  DEN = 'Denver Nuggets',
  DET = 'Detroit Pistons',
  GS = 'Golden State Warriors',
  HOU = 'Houston Rockets',
  IND = 'Indiana Pacers',
  LAC = 'Los Angeles Clippers',
  LAL = 'Los Angeles Lakers',
  MEM = 'Memphis Grizzlies',
  MIA = 'Miami Heat',
  MIL = 'Milwaukee Bucks',
  MIN = 'Minnesota Timberwolves',
  NO = 'New Orleans Pelicans',
  NY = 'New York Knicks',
  OKC = 'Oklahoma City Thunder',
  ORL = 'Orlando Magic',
  PHI = 'Philadelphia 76ers',
  PHO = 'Phoenix Suns',
  POR = 'Portland Trail Blazers',
  SAC = 'Sacramento Kings',
  SA = 'San Antonio Spurs',
  TOR = 'Toronto Raptors',
  UTA = 'Utah Jazz',
  WAS = 'Washington Wizards',
};

export const NBA_COLOR_CODES: {  [key in NBA_TEAM_CODE]: { primary: string, secondary: string } } = {
  ATL: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(253, 185, 39, 0.75)' },
  BOS: { primary: 'rgb(0, 122, 51, 0.75)', secondary: 'rgb(139, 111, 78, 0.75)' },
  BKN: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  CHA: { primary: 'rgb(29, 17, 96, 0.75)', secondary: 'rgb(0, 120, 140, 0.75)' },
  CHI: { primary: 'rgb(206, 17, 65, 0.75)', secondary: 'rgb(6, 25, 34, 0.75)' },
  CLE: { primary: 'rgb(134, 0 , 56, 0.75)', secondary: 'rgb(4, 30, 66, 0.75)' },
  DAL: { primary: 'rgb(0, 83, 188, 0.75)', secondary: 'rgb(0, 43, 92, 0.75)' },
  DEN: { primary: 'rgb(13, 34, 64, 0.75)', secondary: 'rgb(255, 198, 39, 0.75)' },
  DET: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(29, 66, 138, 0.75)' },
  GS: { primary: 'rgb(29, 66, 138, 0.75)', secondary: 'rgb(255, 199, 44, 0.75)' },
  HOU: { primary: 'rgb(206, 17, 65, 0.75)', secondary: 'rgb(6, 25, 34, 0.75)' },
  IND: { primary: 'rgb(0, 45, 98, 0.75)', secondary: 'rgb(253, 187, 48, 0.75)' },
  LAC: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(29, 66, 148, 0.75)' },
  LAL: { primary: 'rgb(85, 37, 130, 0.75)', secondary: 'rgb(253, 185, 39, 0.75)' },
  MEM: { primary: 'rgb(93, 118, 169, 0.75)', secondary: 'rgb(18, 23, 63, 0.75)' },
  MIA: { primary: 'rgb(152, 0, 46, 0.75)', secondary: 'rgb(249, 160, 27, 0.75)' },
  MIL: { primary: 'rgb(0, 71, 27, 0.75)', secondary: 'rgb(240, 235, 210, 0.75)' },
  MIN: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(35, 97, 146, 0.75)' },
  NO: { primary: 'rgb(0, 22, 65, 0.75)', secondary: 'rgb(225, 58, 62, 0.75)' },
  NY: { primary: 'rgb(0, 107, 182, 0.75)', secondary: 'rgb(245, 132, 38, 0.75)' },
  OKC: { primary: 'rgb(0, 125, 195, 0.75)', secondary: 'rgb(239, 59, 36, 0.75)' },
  ORL: { primary: 'rgb(0, 125, 197, 0.75)', secondary: 'rgb(196, 206, 211, 0.75)' },
  PHI: { primary: 'rgb(0, 107, 182, 0.75)', secondary: 'rgb(237, 23, 76, 0.75)' },
  PHO: { primary: 'rgb(29, 17, 96, 0.75)', secondary: 'rgb(229, 95, 32, 0.75)' },
  POR: { primary: 'rgb(224, 58, 62, 0.75)', secondary: 'rgb(6, 25, 34, 0.75)' },
  SAC: { primary: 'rgb(91, 43, 130, 0.75)', secondary: 'rgb(99, 113, 122, 0.75)' },
  SA: { primary: 'rgb(196, 206, 211, 0.75)', secondary: 'rgb(6, 25, 34, 0.75)' },
  TOR: { primary: 'rgb(206, 17, 65, 0.75)', secondary: 'rgb(6, 25, 34, 0.75)' },
  UTA: { primary: 'rgb(0, 43, 92, 0.75)', secondary: 'rgb(0, 71, 27, 0.75)' },
  WAS: { primary: 'rgb(0, 43, 92, 0.75)', secondary: 'rgb(227, 24, 55, 0.75)' },
};